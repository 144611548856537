import { Count, Gondor, Params, Response } from "../Gondor";
import { ApiSetting } from "./ApiSetting";
import { ApiRule } from "./ApiRule";
import { System } from "./System";
import { Ping } from "./Ping";
import { Api } from "./Api";
import { Convertion } from "./Convertion";

export interface ApiSystem {
  id: number;
  active: boolean;
  leadOffer: number;
  redirectUrl: null | string;
  pingtree: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null | Date;
  apiId: number;
  systemId: number;
  api: Api;
  system: Pick<System, "id" | "name">;
}

interface ApiSystemSchema {
  active: boolean;
  leadOffer: number;
  redirectUrl?: string;
  apiId: number;
  systemId: number;
  pingtree: boolean;
}

export interface ApiSystemPing extends Ping {
  apiSystem: Pick<ApiSystem, "id"> & { api: Pick<Api, "id" | "name"> };
}

export interface ApiSystemConvertion extends Convertion {
  apiSystem: Pick<ApiSystem, "id"> & { api: Pick<Api, "id" | "name"> };
}

const ACTIONS = {
  APISYSTEM: "/apiSystem",
  APISYSTEM_ITEM: "/apiSystem/{id}",
  APISYSTEM_SETTING: "/apiSystem/{id}/setting",
  APISYSTEM_RULE: "/apiSystem/{id}/rule",
  APISYSTEM_PING: "/apiSystem/{id}/ping",
  APISYSTEM_CONVERTION: "/apiSystem/{id}/convertion",
};

export const findAll = (params?: Params) => {
  return Gondor.API.get<Response<Count<ApiSystem>>>(ACTIONS.APISYSTEM, {
    params,
  });
};

export const findOne = (apiSystemId: number | string) => {
  return Gondor.API.get<Response<ApiSystem>>(
    ACTIONS.APISYSTEM_ITEM.replace("{id}", String(apiSystemId))
  );
};

export const create = (body: ApiSystemSchema) => {
  return Gondor.API.post(ACTIONS.APISYSTEM, body);
};

export const update = (id: number, body: Partial<ApiSystemSchema>) => {
  return Gondor.API.patch(
    ACTIONS.APISYSTEM_ITEM.replace("{id}", String(id)),
    body
  );
};

export const destroy = (id: number | string) => {
  return Gondor.API.delete(ACTIONS.APISYSTEM_ITEM.replace("{id}", String(id)));
};

export const findSettings = (apiSystemId: number) => {
  return Gondor.API.get<Response<Count<ApiSetting>>>(
    ACTIONS.APISYSTEM_SETTING.replace("{id}", String(apiSystemId))
  );
};

export const findRules = (apiSystemId: number) => {
  return Gondor.API.get<Response<Count<ApiRule>>>(
    ACTIONS.APISYSTEM_RULE.replace("{id}", String(apiSystemId))
  );
};

export const ping = (apiSystemId: number | string, params?: Params) => {
  return Gondor.API.get<Response<Count<ApiSystemPing>>>(
    ACTIONS.APISYSTEM_PING.replace("{id}", String(apiSystemId)),
    { params }
  );
};

export const convertion = (apiSystemId: number | string, params?: Params) => {
  return Gondor.API.get<Response<Count<ApiSystemConvertion>>>(
    ACTIONS.APISYSTEM_CONVERTION.replace("{id}", String(apiSystemId)),
    { params }
  );
};
